import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="677px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          center
          justifyBetween
        >
          <Header />
          <CFView column center pulsate mt="15px" mb="20px" textCenter>
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero})  0% 75% / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
        >
          <Header />
          <CFView row justifyCenter alignStart h="100vh">
            <CFView
              pg="5px"
              bg="rgba(0, 0, 0, 0.7)"
              pv="14px"
              row
              alignCenter
              justifyCenter
              w="100%"
              mt="auto"
              raleway
              xBold
              h1
              white
            >
              NOW TAKING ONLINE ORDERS!
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
